import {Button} from "@/shared/v2";
import React, {ReactElement, useMemo} from "react";

import {ConfirmExitModal} from "@/shared/v2/modals/confirm-exit-modal";
import {useActionsContext} from "../../contexts/actions-context";
import {useConfirmationBlocker} from "@/hooks";
import {useNavigationContext} from "../../contexts/navigation-context";
import {useSelectedCampaignsContext} from "../../contexts/selected-campaigns-context";
import {useSelectedSourcesContext} from "../../contexts/selected-sources-context";

import styles from "./buttons.module.scss";

export const CancelButton = (): ReactElement => {
  const {close} = useNavigationContext();
  const {isDirty: hasSourcesChanges} = useSelectedSourcesContext();
  const {isDirty: hasCampaignsChanges} = useSelectedCampaignsContext();
  const {isSubmittingRef} = useActionsContext();

  const text = useMemo(() => {
    if (hasSourcesChanges && hasCampaignsChanges) {
      return "dataset or campaign";
    } else if (hasSourcesChanges) {
      return "dataset";
    } else {
      return "campaign";
    }

  }, [hasSourcesChanges, hasCampaignsChanges]);

  const unsavedChangesModal = useConfirmationBlocker({
		shouldBlock: () => (hasSourcesChanges || hasCampaignsChanges) && !isSubmittingRef.current,
		allowSameLocation: false,
		renderPrompt: ({isOpen, cancel, proceed}) => (
			<ConfirmExitModal
				title="Confirm Exit"
				description={`
					Any changes you've made to your ${text} selections haven't been saved.
					Do you want to exit?
				`}
				isOpen={isOpen}
				onClose={cancel}
				onConfirm={proceed}
				portal
			/>
		),
	});

  return (
    <>
      <Button
        variant="outlined"
        className={styles.commitButton}
        onClick={close}
      >
        Cancel
      </Button>

      {unsavedChangesModal}
    </>
  );
}
