import React, {cloneElement, ReactElement, useRef} from "react";

import {motion} from "framer-motion";
import {CHAT_TOOLS_ICONS, CHAT_TOOLS_NAMES} from "@/shared/constants/constants";
import {ChatConversationMode, ChatImageTool, ChatLayoutMode, ChatSocialTool, ChatTool, ChatToolValue, ChatWebTool, useChatConversationModeContext, useChatLayoutModeContext, useChatToolsContext} from "@/context/chat-contexts";
import {Section} from "./section";
import {useOnClickOutside} from "@/hooks";
import {useThemeMode} from "@/context/theme-mode-context";
import classNames from "classnames/bind";

import styles from "./chat-bubble-drawer.module.scss";

const cx = classNames.bind(styles);

export const ChatBubbleDrawer = (): ReactElement => {
  const {
    setTool,
    setSocialTool,
    setImageTool,
    setWebTool,
  } = useChatToolsContext();
  const {setMode} = useChatConversationModeContext();
  const {mode: layoutMode, setIsDrawerOpen} = useChatLayoutModeContext();
  const {isDarkMode} = useThemeMode();
  const drawerRef = useRef<HTMLDivElement>(null);
  const isAnimatingRef = useRef(false);

  useOnClickOutside(drawerRef, () => {
    if (isAnimatingRef.current) {
      return;
    };
    setIsDrawerOpen(false);
  }, "mousedown");

  const addClassToIcon = (icon: ReactElement, className: string) => {
    if (!icon) return icon; // Return the icon as-is instead of null
    return cloneElement(icon, {
      className: cx(icon.props?.className, className),
    });
  }

  const CLASS_NAMES = {
    [ChatImageTool.DALLE]: styles.iconImageDE,
    [ChatImageTool.IMAGEN]: styles.iconImageImagen,
    [ChatImageTool.STABLE_DIFFUSION]: styles.iconImageSD,
    [ChatSocialTool.INSTAGRAM]: styles.iconInstagram,
    [ChatSocialTool.LINKEDIN]: styles.iconLinkedin,
    [ChatSocialTool.REDDIT]: styles.iconReddit,
    [ChatSocialTool.TIKTOK]: styles.iconTiktok,
    [ChatSocialTool.X_TWITTER]: styles.iconXTwitter,
    [ChatSocialTool.YOUTUBE]: styles.iconYoutube,
    [ChatWebTool.SCRAPE_ADVANCED]: styles.iconScrapeAdvanced,
    [ChatWebTool.SCRAPE]: styles.iconScrape,
    [ChatWebTool.WEB]: styles.iconWeb,
  }

  const createOption = (value: ChatToolValue) => ({
    value,
    label: CHAT_TOOLS_NAMES[value],
    icon: addClassToIcon(CHAT_TOOLS_ICONS[value], CLASS_NAMES[value]),
  })

  const socialOptions = (Object.values(ChatSocialTool) as ChatSocialTool[]).map(createOption);
  const imageOptions = (Object.values(ChatImageTool) as ChatImageTool[]).map(createOption);
  const webOptions = [
    createOption(ChatWebTool.WEB),
    createOption(ChatWebTool.SCRAPE),
    createOption(ChatWebTool.SCRAPE_ADVANCED),
  ];

  const handleSocialSelect = (value: ChatToolValue) => {
    setMode(ChatConversationMode.CONVERSATION);
    setSocialTool(value as ChatSocialTool);
    setTool(ChatTool.SOCIAL);
  };

  const handleImageSelect = (value: ChatToolValue) => {
    setImageTool(value as ChatImageTool);
    setMode(ChatConversationMode.CONVERSATION);
    setTool(ChatTool.IMAGE);
  };

  const handleWebSelect = (value: ChatToolValue) => {
    setMode(ChatConversationMode.CONVERSATION);
    setTool(ChatTool.WEB);
    setWebTool(value as ChatWebTool);
  };

  return (
    <div
      className={styles.popup}
      ref={drawerRef}
    >
      <motion.div
        className={
          cx(
            "chatBubbleDrawer",
            layoutMode === ChatLayoutMode.CHAT ? "chat" : "home",
            {isDarkMode},
          )
        }
        onAnimationStart={() => isAnimatingRef.current = true}
        onAnimationComplete={() => isAnimatingRef.current = false}
        initial={{
          height: 0,
          opacity: 0,
        }}
        animate={{
          height: "314px",
          opacity: 1,
        }}
        exit={{
          height: 0,
          opacity: 0,
        }}
        transition={{
          height: { duration: 0.3, ease: "easeInOut" },
          opacity: { duration: 0.4, ease: "easeInOut" },
        }}
      >
        <Section
          name="Social Channels"
          options={socialOptions}
          onSelect={handleSocialSelect}
        />

        <Section
          name="Research"
          options={webOptions}
          onSelect={handleWebSelect}
        />

        <Section
          name="Image Models"
          options={imageOptions}
          onSelect={handleImageSelect}
        />
      </motion.div>
    </div>
  );
}
