import React, {ReactElement, ReactNode} from "react";
import {
	ChatCampaignContextProvider,
	ChatConversationContextProvider,
	ChatEventsContextProvider,
	ChatFollowUpsContextProvider,
	ChatImageContextProvider,
	ChatDocumentContextProvider,
	ChatMentionsContextProvider,
	ChatMessagesContextProvider,
	ChatLayoutModeContextProvider,
	ChatPersonaContextProvider,
	ChatScrollContextProvider,
	ChatSendQuestionContextProvider,
	ChatSourcesContextProvider,
	ChatToolsContextProvider, ChatConversationModeContextProvider
} from "../../context/chat-contexts";
import {ChatHandlers} from "./handlers";
import {ImageHistoryContextProvider} from "../image-studio/contexts";
import {ChatStateContextProvider} from "@/context/chat-contexts/chat-state-context";

export interface ChatContextsAndHandlersProps {
  children?: ReactNode;
}

export const ChatContextsAndHandlers = ({children}: ChatContextsAndHandlersProps): ReactElement => {
	return (
		<ChatStateContextProvider>
			<ImageHistoryContextProvider>
				<ChatScrollContextProvider>
					<ChatEventsContextProvider>
						<ChatMentionsContextProvider>
							<ChatFollowUpsContextProvider>
								<ChatImageContextProvider>
									<ChatDocumentContextProvider>
										<ChatConversationContextProvider>
											<ChatConversationModeContextProvider>
												<ChatPersonaContextProvider>
													<ChatMessagesContextProvider>
														<ChatToolsContextProvider>
															<ChatSourcesContextProvider>
																<ChatCampaignContextProvider>
																		<ChatSendQuestionContextProvider>
																			<ChatLayoutModeContextProvider>
																				<ChatHandlers />
																				{children}
																			</ChatLayoutModeContextProvider>
																		</ChatSendQuestionContextProvider>
																</ChatCampaignContextProvider>
															</ChatSourcesContextProvider>
														</ChatToolsContextProvider>
													</ChatMessagesContextProvider>
												</ChatPersonaContextProvider>
											</ChatConversationModeContextProvider>
										</ChatConversationContextProvider>
									</ChatDocumentContextProvider>
								</ChatImageContextProvider>
							</ChatFollowUpsContextProvider>
						</ChatMentionsContextProvider>
					</ChatEventsContextProvider>
				</ChatScrollContextProvider>
			</ImageHistoryContextProvider>
		</ChatStateContextProvider>
	);
}
