import classNames from "classnames/bind";
import React, {ReactElement, useEffect, useState} from "react";

import {Body} from "../../../../shared/v2";
import {ConversationEventTypes} from "../../../../models/subscriptions";
import {Persona} from "../../../../models";
import {useChatEventsContext} from "../../../../context/chat-contexts";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {useUnsafeAgentCreationContext} from "../../../../context/agent-creation-context";

import styles from "./loading-content.module.scss";

const cx = classNames.bind(styles);

export interface LoadingContentProps {
  persona?: Persona;
}

export const LoadingContent = ({persona}: LoadingContentProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {
		state: {
			name: agentCreationName = undefined,
		} = {}
	} = useUnsafeAgentCreationContext() ?? {};
	const {events: {
		[ConversationEventTypes.RESPONSE_STATE_CHANGED]: responseStateChanges,
	}, removeEvent} = useChatEventsContext();
	const [stateHistory, setStateHistory] = useState<string[]>([]);

	useEffect(() => {
		if (responseStateChanges.length > 0) {
			const event = responseStateChanges[0];
			const {data: {content}} = event;

			// Skip verifyUrl-related state changes
			if (content.includes('verifyUrl') || 
				content.includes('Starting verifyUrl') || 
				content.includes('Completed verifyUrl') || 
				content.includes('Using verifyUrl')) {
				removeEvent(event);
				return;
			}

			// Add new state to history if it's different from the last one
			setStateHistory(prev => {
				const lastState = prev[prev.length - 1];
				if (lastState !== content) {
					return [...prev, content];
				}
				return prev;
			});

			removeEvent(event);
		}
	}, [responseStateChanges, removeEvent]);

	return (
		<Body className={cx("loadingWrapper", {isDarkMode})}>
			<div className={styles.stateHistory}>
				{stateHistory.length === 0 ? (
					<span>
						{`${persona?.name || agentCreationName || "V"} is generating`}
						<span className={styles.loadingIcon}></span>
					</span>
				) : (
					stateHistory.map((state, index) => (
						<div key={index} className={cx("stateItem", { latest: index === stateHistory.length - 1 })}>
							{state}
							{index === stateHistory.length - 1 && <span className={styles.loadingIcon}></span>}
						</div>
					))
				)}
			</div>
		</Body>
	);
}
