import {useEffect} from "react";

import {useChatConversationContext, useChatMessagesContext, useChatSendQuestionContext} from "../../../context/chat-contexts";

export const useChatListInitialPopulate = () => {
	const {conversation: {id, messages} = {}} = useChatConversationContext();
	const {setMessages} = useChatMessagesContext();
	const {isSendingFirstQuestion} = useChatSendQuestionContext();

	const trigger = `${id}-${messages?.map(({id, content}) => `${id}-${content}`).join("-")}`;

	const shouldUpdate = () => {
		return Boolean(messages && !isSendingFirstQuestion);
	}

	useEffect(() => {
		if (!shouldUpdate()) {
			return;
		}
		setMessages(messages || []);
	}, [trigger]);
};
