import React, {FormEvent, ReactElement, useCallback, useState} from "react";
import classNames from "classnames/bind";

import {AiChipIcon, CheckmarkIcon, InformationCicrcleIcon, PaperclipIcon, PersonaIcon} from "@/icons";
import {Body, Button, Separator, Subheader, Switch, Tooltip} from "@/shared/v2";
import {CHAT_COMMANDS, CHAT_TOOLS_PLACEHOLDERS, TOOLTIP_DELAY_MEDIUM, TOOLTIP_DELAY_SHORT} from "@/shared/constants/constants";
import {KeyboardEventMiddlewareContextProvider} from "@/context/keyboard-event-middleware-context";
import {SelectChatSourcesModal} from "@/canvas/select-chat-sources-modal";
import {SelectPersonaModal} from "@/canvas/select-persona-modal";
import {SourcesSection} from "../sources-section";
import {SubmitButton} from "../submit-button";
import {UploadButton} from "../upload-button";
import {UploadedImageSection} from "../uploaded-image-section";
import {useChatConversationContext, useChatSendQuestionContext, useChatFollowUpsContext, useChatImageContext, useChatPersonaContext, useChatLayoutModeContext, useChatConversationModeContext, ChatConversationMode, useChatCampaignContext, useChatSourcesContext, ChatLayoutMode, useChatToolsContext, ChatImageTool, ChatSocialTool, ChatWebTool} from "@/context/chat-contexts";
import {useModalActions} from "@/hooks";
import {useThemeMode} from "@/context/theme-mode-context";
import {useWorkspaceContext} from "@/context/workspace-context";
import CommandTextArea from "../command-text-area";

import styles from "./input-bubble.module.scss";

const cx = classNames.bind(styles);

export interface InputBubbleProps {
  init?: string;
}

export const InputBubble = ({init = ""}: InputBubbleProps): ReactElement => {
  const {isDarkMode} = useThemeMode();
	const {conversation} = useChatConversationContext();
	const {followUps} = useChatFollowUpsContext();
	const {image} = useChatImageContext();
	const {sendQuestion, isSendingQuestion} = useChatSendQuestionContext();
	const {workspace} = useWorkspaceContext();
  const {setIsDrawerOpen} = useChatLayoutModeContext();
  const {mode: conversationMode, setMode} = useChatConversationModeContext();
  const {mode: layoutMode, isDrawerOpen} = useChatLayoutModeContext();
  const {active: sources} = useChatSourcesContext();
  const {tool} = useChatToolsContext();
  const {activeCampaigns: campaigns} = useChatCampaignContext();

	const [value, setValue] = useState(init);
	const isDisabled = !workspace.id || (!value.length && !image) || isSendingQuestion;
	const {personas} = useChatPersonaContext();

  const isUsingAllTools = conversationMode === ChatConversationMode.REASONING;
  const isUsingAllSources = (
    conversationMode === ChatConversationMode.REASONING &&
    (
      sources.media.files.length === 0 &&
      sources.trainingSets.length === 0 &&
      sources.media.videos.length === 0 &&
      campaigns.length === 0
    )
  )

  const {
		value: isSelectPersonaModalOpen,
		open: openSelectPersonaModal,
		close: closeSelectPersonaModal,
	} = useModalActions();

  const {
    value: isSelectChatSourcesModalOpen,
    open: openSelectChatSourcesModal,
    close: closeSelectChatSourcesModal,
  } = useModalActions({search: "chat-sources"});

	const clearInput = () => {
		setValue("");
	};

	const handleResponse = useCallback(async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
		e?.preventDefault();

		if (isDisabled) {
			return;
		}

		clearInput();
		await sendQuestion(value);
	}, [isDisabled, value, sendQuestion, conversation, setValue]);

  return (
    <>
      <div className={cx(
        "inputBubble",
        layoutMode === ChatLayoutMode.CHAT ? "chat" : "home",
        {
          isDarkMode,
          drawer: isDrawerOpen,
        })
      }>
        <div className={styles.inputAndSendSection}>
          <KeyboardEventMiddlewareContextProvider>
            <CommandTextArea
              followUps={followUps}
              value={value}
              onChange={setValue}
              placeholder={tool ? CHAT_TOOLS_PLACEHOLDERS[tool] : "Ask anything..."}
              commands={CHAT_COMMANDS}
              personas={personas}
              handleSubmit={handleResponse}
            />
          </KeyboardEventMiddlewareContextProvider>

          <UploadedImageSection />

          <UploadButton />
          <SubmitButton onSubmit={handleResponse} />
        </div>

        <SourcesSection className={styles.attachmentsSection} hideIfEmpty />

        <Separator className={styles.separator} />

        <div className={styles.controlSection}>
          <Button
            style="toolkit"
            className={styles.toolkitButton}
            size="small"
            leftIcon={<PersonaIcon />}
            onClick={openSelectPersonaModal}
          >
            Agent
          </Button>

          <Button
            style="toolkit"
            className={styles.toolkitButton}
            size="small"
            disabled={tool !== null}
            onClick={openSelectChatSourcesModal}
            leftIcon={<PaperclipIcon />}
            rightIcon={<CheckmarkIcon className={cx("checkmark", {hidden: !isUsingAllSources, isDarkMode})} />}
          >
            Sources
          </Button>


          <Button
            style="toolkit"
            className={styles.toolkitButton}
            size="small"
            leftIcon={<AiChipIcon />}
            rightIcon={<CheckmarkIcon className={cx("checkmark", {hidden: !isUsingAllTools, isDarkMode})} />}
            onMouseDown={() => setIsDrawerOpen(current => !current)}
          >
            Tools
          </Button>


          <div className={styles.controlsSpace} />
          <Switch
            checked={conversationMode === ChatConversationMode.REASONING}
            onChange={() => {
              setMode(current => current === ChatConversationMode.REASONING ? ChatConversationMode.CONVERSATION : ChatConversationMode.REASONING);
            }}
          />

          <Body size="s" type="semibold" className={cx("autoLabel", {disabled: conversationMode !== ChatConversationMode.REASONING})}>
            Auto
          </Body>

            <Tooltip
              startDelay={TOOLTIP_DELAY_SHORT}
              placement="top"
              className={styles.tooltipContent}
              content={
                <div className={styles.tooltipItems}>
                  <div>
                    <Subheader type="bold">
                      Auto mode ON
                    </Subheader>
                    <Body size="s">
                      Will automatically use all available sources and tools to intelligently respond.
                    </Body>
                  </div>
                  <div>
                    <Subheader type="bold">
                     Auto mode OFF
                      </Subheader>

                      <Body size="s">
                        Will allow you to manually select only desired sources or tools.
                      </Body>
                    </div>
                  </div>
              }
            >
              <InformationCicrcleIcon className={styles.infoIcon} />
            </Tooltip>
        </div>
      </div>

      <SelectPersonaModal
				isOpen={isSelectPersonaModalOpen}
				onClose={closeSelectPersonaModal}
			/>

      <SelectChatSourcesModal
        isOpen={isSelectChatSourcesModalOpen}
        onClose={closeSelectChatSourcesModal}
      />
    </>
  );
}
