import React, {cloneElement, ReactElement, useMemo} from "react";
import classNames from "classnames/bind";

import {Campaign, TrainingSetFile, TrainingSetMedia, TrainingSetVideo} from "../../../models/ai-model";
import {CHAT_TOOLS_ICONS, CHAT_TOOLS_NAMES} from "@/shared/constants/constants";
import {ChatImageTool, ChatSocialTool, ChatToolValue, ChatWebTool, useChatCampaignContext, useChatDocumentContext, useChatSourcesContext, useChatToolsContext} from "../../../context/chat-contexts";
import {DocumentsFolderFileIcon, FileTextIcon, FileUploadIcon, FileVideoIcon, HornMegaphoneCampaignIcon} from "../../../icons";
import {InputChip} from "../../../shared/components/input-chip";
import {TrainingSet} from "../../../models";

import styles from "./sources-section.module.scss";

const cx = classNames.bind(styles);

export type ChatChipType = ChatToolValue | TrainingSet | TrainingSetMedia | Campaign | File;

export const isTool = (source: ChatChipType): source is ChatToolValue => {
	return typeof source === "string";
}

export const isFile = (source: ChatChipType): source is File => {
	return typeof source !== "string" && source instanceof File;
}

export const isTrainingSet = (source: ChatChipType): source is TrainingSet => {
	return typeof source !== "string" && "alias" in source;
}

export const isTrainingSetFile = (source: ChatChipType): source is TrainingSetFile => {
	return typeof source !== "string" && "__typename" in source && source.__typename === "File";
}

export const isTrainingSetVideo = (source: ChatChipType): source is TrainingSetVideo => {
	return typeof source !== "string" && "__typename" in source && source.__typename === "TrainingSetVideo";
}

export interface SourcesSectionProps {
	hideIfEmpty?: boolean;
	className?: string;
}

export const SourcesSection = ({hideIfEmpty, className}: SourcesSectionProps): ReactElement => {
	const {
		active: {
			trainingSets: activeTrainingSets,
			media: activeMedia,
		},
		save: saveSources,
		isUpdatingDisabled: isSourcesUpdatingDisabled,
	} = useChatSourcesContext();
	const {tool: activeTool, setTool} = useChatToolsContext();
	const {
		activeCampaigns,
		saveCampaigns,
		isUpdatingDisabled: isCampaignUpdatingDisabled,
	} = useChatCampaignContext();
	const {
		document: activeUpload,
		clearDocument,
	} = useChatDocumentContext();

	const handleRemoveTrainingSet = (trainingSet: TrainingSet) => {
		saveSources({
			trainingSets: activeTrainingSets?.filter(ts => ts.id !== trainingSet.id).map(ts => ts.id) || [],
		})
	}

	const handleRemoveVideo = (video: TrainingSetVideo) => {
		saveSources({
			media: {
				videos: activeMedia.videos?.filter(v => v.id !== video.id).map(v => v.id) || [],
			},
		});
	}

	const handleRemoveFile = (file: TrainingSetFile) => {
		saveSources({
			media: {
				files: activeMedia.files?.filter(f => f.id !== file.id).map(f => f.id) || [],
			},
		});
	}

	const handleRemoveCampaign = (campaign: Campaign) => {
		saveCampaigns(activeCampaigns.filter(c => c.id !== campaign.id).map(c => c.id));
	}

	const sources = useMemo(() => {
		const activeTools = activeTool ? [activeTool] : [];
		const activeUploads = activeUpload ? [activeUpload] : [];

		return [...activeTools, ...activeUploads, ...(activeTrainingSets || []), ...activeCampaigns, ...activeMedia.files, ...activeMedia.videos];
	}, [activeTool, activeUpload, activeTrainingSets, activeMedia, activeCampaigns]);

	const CLASS_NAMES = {
    [ChatImageTool.DALLE]: styles.imageDE,
    [ChatImageTool.IMAGEN]: styles.imageImagen,
    [ChatImageTool.STABLE_DIFFUSION]: styles.imageSD,
    [ChatSocialTool.INSTAGRAM]: styles.socialInstagram,
    [ChatSocialTool.LINKEDIN]: styles.socialLinkedin,
    [ChatSocialTool.REDDIT]: styles.socialReddit,
    [ChatSocialTool.TIKTOK]: styles.socialTiktok,
    [ChatSocialTool.X_TWITTER]: styles.socialXTwitter,
    [ChatSocialTool.YOUTUBE]: styles.socialYoutube,
    [ChatWebTool.SCRAPE_ADVANCED]: styles.web,
    [ChatWebTool.SCRAPE]: styles.web,
    [ChatWebTool.WEB]: styles.web,
  }

	const renderSource = (source: ChatChipType) => {
		if (isTool(source)) {
			return (
				<InputChip
					className={cx("source", "tool", CLASS_NAMES[source])}
					icon={cloneElement(CHAT_TOOLS_ICONS[source], {className: styles.chipIcon})}
					key="tool"
					onRemove={() => setTool(null)}
					label={CHAT_TOOLS_NAMES[source]}
					variant="squared"
					size="small"
				/>
			)
		}
		if (isFile(source)) {
			return (
				<InputChip
					className={styles.source}
					icon={<FileUploadIcon />}
					key="file-uppload"
					onRemove={() => clearDocument()}
					disabled={isSourcesUpdatingDisabled || Boolean(activeTool)}
					label={source.name}
					variant="squared"
					size="small"
				/>
			)
		}
		if (isTrainingSet(source)) {
			return (
				<InputChip
					className={styles.source}
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveTrainingSet(source)}
					disabled={isSourcesUpdatingDisabled || Boolean(activeTool)}
					label={source.alias}
					variant="squared"
					size="small"
				/>
			);
		}
		if (isTrainingSetVideo(source)) {
			return (
				<InputChip
					variant="squared"
					className={styles.source}
					icon={<FileVideoIcon />}
					key={source.id}
					disabled={isSourcesUpdatingDisabled || Boolean(activeTool)}
					onRemove={() => handleRemoveVideo(source)}
					label={source.originalFilename}
					size="small"
				/>
			);
		}
		if (isTrainingSetFile(source)) {
			return (
				<InputChip
					variant="squared"
					className={styles.source}
					icon={<FileTextIcon />}
					key={source.id}
					disabled={isSourcesUpdatingDisabled || Boolean(activeTool)}
					onRemove={() => handleRemoveFile(source)}
					label={source.originalFilename}
					size="small"
				/>
			);
		}

		return (
			<InputChip
				variant="squared"
				className={styles.source}
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				disabled={isCampaignUpdatingDisabled || Boolean(activeTool)}
				onRemove={() => handleRemoveCampaign(source)}
				label={source.name}
				size="small"
			/>
		);
	};

	if (!sources.length || (hideIfEmpty && !sources.length)) {
		return <></>;
	}

	return (
		<div className={cx("sourcesSection", className)}>
			{sources.map(renderSource)}
		</div>
	);
}
