import React, {ReactElement} from "react";

import {Body, Caption, Separator} from "@/shared/v2";
import {ChatToolValue, useChatToolsContext} from "@/context/chat-contexts";
import {CheckmarkIcon} from "@/icons";
import classNames from "classnames/bind";

import styles from "./section.module.scss";
import {useThemeMode} from "@/context/theme-mode-context";

const cx = classNames.bind(styles);

export interface SectionOption {
  value: ChatToolValue;
  label: string;
  icon: ReactElement;
}

export interface SectionProps {
  name: string;
  options: SectionOption[];
  onSelect: (value: ChatToolValue) => void;
}

export function Section({name, options, onSelect}: SectionProps): ReactElement {
  const {tool: activeTool} = useChatToolsContext();
  const {isDarkMode} = useThemeMode();

  return (
    <div className={styles.wrapper}>
      <Separator className={styles.separator} color="workflow"  />
      <Caption
        size="s"
        color="text-secondary"
        type="semibold"
        className={styles.header}
      >
        {name}
      </Caption>

      <div className={styles.options}>
        {options.map(({value, label, icon}) => (

          <button
            onClick={() => onSelect(value)}
            className={cx("option", {active: activeTool === value})}
          >
            {icon}
            <Body size="s">{label}</Body>
            <div className={styles.spacing} />
            {activeTool === value && <CheckmarkIcon className={cx("checkmark", {isDarkMode})} />}
          </button>
        ))}
      </div>
    </div>
  );
}
