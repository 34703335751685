import React, {ReactElement, useMemo} from "react";

import {CampaignCard} from "@/canvas/select-campaign-modal/campaign-card";
import {useSearchContext} from "@/canvas/select-chat-sources-modal/contexts/search-context";
import {useSelectedCampaignsContext} from "@/canvas/select-chat-sources-modal/contexts/selected-campaigns-context";

import styles from "./select-campaign-tab.module.scss";

export const SelectCampaignTab = (): ReactElement => {
  const {
    all,
    selected,
    add,
    remove,
  } = useSelectedCampaignsContext();
  const {search} = useSearchContext();

  const filtered = useMemo(() => {
    return all.filter((campaign) => campaign.name.toLowerCase().includes(search.toLowerCase()));
  }, [all, search]);

  return (
    <div className={styles.selectCampaignTab}>
      {
        filtered.map((campaign) => {
          const isSelected = selected.some((activeCampaign) => activeCampaign.id === campaign.id);

          return (
            <CampaignCard
              key={campaign.id}
              campaign={campaign}
              isSelected={isSelected}
              onSelect={() => isSelected ? remove([campaign]) : add([campaign])}
            />
          );
        })
      }
    </div>
  );
}
