import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import styles from "./preview-modal.module.scss";
import {Clip} from "../../../models/clip";
import {getCurrent} from "../../../ref";
import {convertTimeFromMiliseconds} from "../../../shared/utility/utility";
import {TipBanner} from "../../../shared/components/tip-banner";
import {PlayButton} from "../../../shared/components/play-button";
import {BaseModalProps, Button, Modal} from "../../../shared/v2";

export interface PreviewModalProps extends BaseModalProps {
	clips: Clip[];
}

const PreviewModal = (props: PreviewModalProps): ReactElement => {
	const {onClose, isOpen, clips} = props;
	const ref = useRef<HTMLVideoElement>(null);
	const [index, setIndex] = useState(0);
	const [isPlaying, setIsPlaying] = useState(false);
	const maxIndex = useMemo(() => clips.length, [clips]);

	const setStartTime = (): void => {
		const current = getCurrent(ref);
		if (current) current.currentTime = convertTimeFromMiliseconds(clips[index].startTime, "seconds");
	};

	// We will hit here if the "endTime" is higher than the actual video
	const handleEndClip = (): void => {
		const current = getCurrent(ref);
		if (!current) return;
		if (index === maxIndex - 1) return setIsPlaying(false);
		setIndex(prev => prev + 1);
	};

	const togglePlaying = (): void => {
		const current = getCurrent(ref);
		if (!current) return;
		setIsPlaying(prev => !prev);
	};

	const handleRestart = (): void => {
		const current = getCurrent(ref);
		if (!current) return;
		if (index === 0) {
			setStartTime();
		} else {
			setIndex(0);
		}
		setIsPlaying(false);
	};



	useEffect(() => {
		const current = getCurrent(ref);
		if (!current) return;
		// current.onloadedmetadata = setStartTime;
		current.onended = handleEndClip;
		return () => {
			if (current) {
				// current.removeEventListener("loadedmetadata", setStartTime);
				current.removeEventListener("ended", handleEndClip);
			}
		};
	}, [ref, ref.current, isPlaying, index]);

	useEffect(() => {
		const current = getCurrent(ref);
		if (!current) return;
		if (isPlaying) {
			current.play();
		} else {
			current.pause();
		}
	}, [isPlaying, ref, ref.current, index]);

	useEffect(() => {
		if (index >= 0) setStartTime();
	}, [index]);

	useEffect(() => {
		if (!isOpen) {
			setIndex(0);
			if (isPlaying) setIsPlaying(false);
		}
		if (isOpen) setStartTime();
	}, [isOpen]);

	return (
		<Modal
			className={styles.modal}
			isOpen={isOpen}
			onClose={onClose}
			title="Preview Reel"
			description={<TipBanner>* This is just a preview, your final reel might differ slightly</TipBanner>}
		>
			<div className={styles.container}>
				<video
					ref={ref}
					className={styles.video}
					src={clips[index]?.answer?.video.mp4 || clips[index]?.sourceVideo?.mp4}
					onContextMenu={event => event.preventDefault()}
				/>
				<PlayButton onClick={togglePlaying} isPlaying={isPlaying} className={styles.play}/>
			</div>
			<Button className={styles.button} onClick={handleRestart}>Restart</Button>
		</Modal>
	);
};

export {PreviewModal};
