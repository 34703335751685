import classNames from "classnames/bind";
import React, {ReactElement, ReactNode, useMemo} from "react";
import {AnimatePresence, motion} from "framer-motion";

import {Avatar, Body} from "../../../shared/v2";
import {CloseIconWrapper} from "../close-icon-wrapper";
import {useChatConversationContext, useChatPersonaContext} from "../../../context/chat-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";
import {VurveyYellowLogoIcon} from "../../../icons";

import styles from "./persona-tab-wrapper.module.scss";

const cx = classNames.bind(styles);

export interface PersonaTabWrapperProps {
  children?: ReactNode;
}

export const PersonaTabWrapper = ({children}: PersonaTabWrapperProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {activePersona, savePersona} = useChatPersonaContext();
	const {isLoading: isDisabled} = useChatConversationContext();

	const personaTab = useMemo(() => {
		if (!activePersona) {
			return null;
		}

		const {isVurvey, picture, name} = activePersona;

		return (
			<motion.div
				initial={{y: "33px"}}
				animate={{y: 0}}
				exit={{y: "33px"}}
				transition={{duration: 0.3}}
			>
				<CloseIconWrapper
					disabled={isDisabled}
					onClose={() => savePersona()}
					className={cx("personaBookmark", {isDarkMode})}
				>
					<Avatar
						firstName={activePersona.name}
						size="xs"
						url={picture?.url}
						additionalIcon={isVurvey ? <VurveyYellowLogoIcon /> : undefined}
						key={picture?.url}
					/>

					<Body className={styles.text} size="s" type="medium">
						{name}
					</Body>
				</CloseIconWrapper>
			</motion.div>
		)
	}, [activePersona, isDisabled, isDarkMode, savePersona]);

	return (
		<div className={styles.personaTabWrapper}>
			<AnimatePresence>
				{personaTab}
			</AnimatePresence>
			{children}
		</div>
	);
}
