import React, {
	forwardRef,
	ReactElement,
	useEffect
} from "react";
import {AnimatePresence} from "framer-motion";

import {ChatBubbleDrawer} from "./chat-bubble-drawer";
import {InputBubble} from "./input-bubble";
import {PersonaTabWrapper} from "./persona-tab-wrapper";
import {useChatLayoutModeContext, useChatToolsContext} from "@/context/chat-contexts";

import styles from "./chat-bubble.module.scss";

export type ChatBubbleContext = "home" | "chat";

export interface ChatInputBoxProps {
	className?: string;
	promptValue?: string;
}

export const ChatBubble = forwardRef<HTMLDivElement, ChatInputBoxProps>(({promptValue}, ref): ReactElement => {
	const {isDrawerOpen, setIsDrawerOpen} = useChatLayoutModeContext();
	const {tool: activeTool} = useChatToolsContext();

	useEffect(() => {
		setIsDrawerOpen(false);
	}, [activeTool]);

	return (
		<div className={styles.chatBubbleWrapper} ref={ref}>
			<PersonaTabWrapper>
				<InputBubble init={promptValue} />
				<AnimatePresence>
					{isDrawerOpen && <ChatBubbleDrawer />}
				</AnimatePresence>
			</PersonaTabWrapper>
		</div>
	);
});
