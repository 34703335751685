import {useEffect, useRef} from "react";
import {useSearchParams} from "react-router-dom";

export const useSearchParamsInvokeCascade = (toInvoke: (() => any)[]) => {
  const invokeIndexRef = useRef(0);
  const [searchParams] = useSearchParams();

  const invoke = () => {
    toInvoke[invokeIndexRef.current]();
    invokeIndexRef.current++;

    if (invokeIndexRef.current === toInvoke.length) {
      invokeIndexRef.current = 0;
    }
  }

  useEffect(() => {
    if (invokeIndexRef.current > 0 && invokeIndexRef.current < toInvoke.length) {
      invoke();
    }
  }, [searchParams]);

  return invoke;
};
