import React, {ReactElement} from "react";

import {
	ChatCampaignContextProvider,
	ChatConversationContextProvider,
	ChatConversationModeContextProvider,
	ChatImageContextProvider,
	ChatPersonaContextProvider,
	ChatSourcesContextProvider,
	ChatStateContextProvider,
	ChatToolsContextProvider
} from "../../../../../context/chat-contexts";
import {ChatDocumentContextProvider} from "../../../../../context/chat-contexts/chat-document-context";

export interface DrawerContextsProps {
	children: ReactElement;
}

export const DrawerContexts = ({children}: DrawerContextsProps): ReactElement => {
	return (
		<ChatConversationContextProvider>
			<ChatStateContextProvider>
				<ChatConversationModeContextProvider>
					<ChatToolsContextProvider>
						<ChatSourcesContextProvider>
							<ChatCampaignContextProvider>
								<ChatConversationModeContextProvider>
									<ChatPersonaContextProvider>
										<ChatImageContextProvider>
											<ChatDocumentContextProvider>
												{children}
											</ChatDocumentContextProvider>
										</ChatImageContextProvider>
									</ChatPersonaContextProvider>
								</ChatConversationModeContextProvider>
							</ChatCampaignContextProvider>
						</ChatSourcesContextProvider>
					</ChatToolsContextProvider>
				</ChatConversationModeContextProvider>
			</ChatStateContextProvider>
		</ChatConversationContextProvider>
	);
}
