import {useChatCampaignContext} from "@/context/chat-contexts";
import {Campaign} from "@/models/ai-model";
import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";

export interface SelectedCampaignsContextValue {
  all: Campaign[];
  selected: Campaign[];
  isDirty: boolean;
  add: (source: Campaign[]) => void;
  remove: (source: Campaign[]) => void;
  set: (sources: Campaign[]) => void;
  save: (soures?: Campaign[]) => Promise<void>;
}

export const SelectedCampaignsContext =
  createContext<SelectedCampaignsContextValue | undefined>(undefined);

export const SelectedCampaignsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {
    campaigns: allCampaigns,
    activeCampaigns: init,
    saveCampaigns,
  } = useChatCampaignContext();
  const [selected, setSelected] = useState<Campaign[]>([...init]);

  useEffect(() => {
    setSelected([...init]);
  }, [init]);

  const isDirty = useMemo(() => {
    return isChanged(init, selected);
  }, [init, selected]);

  const addCampaigns = (campaigns: Campaign[]): void => {
    setSelected([...selected, ...campaigns]);
  }

  const removeCampaigns = (campaigns: Campaign[]): void => {
    setSelected(selected.filter((selectedCampaign) => !campaigns.includes(selectedCampaign)));
  }

  const save = async (campaigns?: Campaign[]): Promise<void> => {
    const idsToSave = (campaigns || selected).map((campaign) => campaign.id);

    await saveCampaigns(idsToSave);
  }

  return (
    <SelectedCampaignsContext.Provider value={{
      all: allCampaigns,
      selected,
      isDirty,
      add: addCampaigns,
      remove: removeCampaigns,
      set: setSelected,
      save,
    }}>
      {children}
    </SelectedCampaignsContext.Provider>
  );
};

export const useSelectedCampaignsContext = (): SelectedCampaignsContextValue => {
  const context = React.useContext(SelectedCampaignsContext);

  if (context === undefined) {
    throw new Error(
      "useSelectedCampaignsContext must be used within a SelectedCampaignsContextProvider",
    );
  }

  return context;
};

function isChanged(original: Campaign[], current: Campaign[]): boolean {
  const originalIds = original.map((campaign) => campaign.id);

  return (
    original.length !== current.length ||
    current.some(({id}) => !originalIds.includes(id))
  );
}
