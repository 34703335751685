import React, {ReactElement, useEffect, useMemo, useState} from "react";
import {useQuery} from "@apollo/client";

import {Button, Modal, SearchInput, Spinner} from "../../shared/v2";
import {ConfirmExitModal} from "../../shared/v2/modals/confirm-exit-modal";
import {FilterChip} from "../../shared/components/filter-chip";
import {useToastContext} from "../../context/toast-context";
import {useChatPersonaContext} from "../../context/chat-contexts/chat-persona-context";
import {AI_PERSONA_TYPES} from "../../graphql/queries/ai-models-queries";

import styles from "./select-persona-modal.module.scss";
import {AgentCard} from "../../agents/components/agent-card";

export interface SelectPersonaModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const SelectPersonaModal = ({isOpen, onClose}: SelectPersonaModalProps): ReactElement => {
	const [filter, setFilter] = useState<string[]>([]);
	const [isExitModalOpen, setIsExitModalOpen] = useState(false);
	const [search, setSearch] = useState("");
	const {activePersona, personas, savePersona, isLoading, isUpdatingDisabled} = useChatPersonaContext();
	const {updateToast} = useToastContext();
	const [selectedPersona, setSelectedPersona] = useState(activePersona);

	const {data: agentTypesData} = useQuery(AI_PERSONA_TYPES, {
		fetchPolicy: "cache-first",
	});

	useEffect(() => {
		if (isExitModalOpen && !isOpen) {
			setIsExitModalOpen(false);
		}
	}, [isOpen, isExitModalOpen]);

	useEffect(() => {
		if (isOpen && activePersona) {
			setSelectedPersona(activePersona);
		}
	}, [activePersona, isOpen]);

	const agentTypes = useMemo(() => {
		if (!agentTypesData) {
			return [];
		}

		return agentTypesData.aiPersonaTypes.map(({id, name}) => ({
			id,
			name,
		}));

	}, [agentTypesData])

	const filteredPersonas = useMemo(
		() => (
			personas
				.filter(
					persona => (
						persona.name
							.toLowerCase()
							.includes(search.toLowerCase())
					),
				)
				.filter(
					persona => (
						filter.length === 0
						|| filter.some(f => f === persona.personaType.id)
					),
				)
		),
		[search, personas, filter],
	);


	const handleCloseModal = (): void => {
		setSelectedPersona(undefined);
		setFilter([]);
		setSearch("");
		onClose();
	};

	const handleSubmitPersona = (): void => {
		if (!selectedPersona) return;
		savePersona(selectedPersona.id);
		updateToast({
			type: "success",
			description: "Agent successfully applied!",
		});
		handleCloseModal();
	};

	const renderPersonas = () => {
		if (isLoading) {
			return <Spinner className={styles.spinner}/>;
		}

		return <div className={styles.personasCollection}>
			{filteredPersonas.map(persona => (
				<AgentCard
					key={persona.id}
					persona={persona}
					onSelected={setSelectedPersona}
					isSelected={selectedPersona?.id === persona.id}
				/>
			))
			}
		</div>
	}

	return (
		<>
			<Modal
				title="Select Agent"
				isOpen={isOpen}
				onClose={onClose}
				className={styles.modal}
				aria-label="select-agent"
			>
				<div className={styles.searchWrapper}>
					<div className={styles.filterSection}>
						{agentTypes.map(agentType => (
							<FilterChip
								key={agentType.id}
								label={agentType.name}
								isSelected={filter?.includes(agentType.id)}
								onClick={() => {
									if (filter?.includes(agentType.id)) {
										setFilter(filter.filter(f => f !== agentType.id));
									} else {
										setFilter([...filter, agentType.id]);
									}
								}}
							/>
						))}
					</div>
					<SearchInput
						className={styles.searchInput}
						value={search}
						onChange={setSearch}
					/>
				</div>
				{renderPersonas()}
				<div className={styles.commitButtonsWrapper}>
					<Button
						className={styles.commitButton}
						variant="outlined"
						onClick={() => setIsExitModalOpen(true)}
					>
						Cancel
					</Button>

					<Button
						className={styles.commitButton}
						disabled={!selectedPersona || isUpdatingDisabled}
						onClick={handleSubmitPersona}
					>
						{selectedPersona ? "Use selected" : "Choose agent"}
					</Button>
				</div>
			</Modal>
			<ConfirmExitModal
				title="Confirm Exit"
				description="
					Any changes you've made to your persona selections haven't been saved.
					Do you want to exit?
				"
				isOpen={isExitModalOpen}
				onClose={() => setIsExitModalOpen(false)}
				onConfirm={handleCloseModal}
			/>
		</>
	);
};
