import {useMutation} from "@apollo/client";
import React, {MutableRefObject, ReactElement, ReactNode, createContext, useMemo, useState} from "react";

import {generateChatMessageLoading} from "../../canvas/chat/utils/generateChatMessageLoading";
import {generateFakeInputMessage} from "../../canvas/chat/utils/generateFakeInputMessage";
import {CHAT_SEND_QUESTION} from "../../graphql/mutations/ai-mutations";
import {ChatConversationMessage} from "../../models/ai-model";
import {useSearchParams} from "../../route";
import {useWorkspaceContext} from "../workspace-context";
import {ChatSendQuestionResponse, SendQuestionOptions, useChatConversationContext} from "./chat-conversation-context";
import {useChatFollowUpsContext} from "./chat-follow-ups-context";
import {useChatImageContext} from "./chat-image-context";
import {useChatDocumentContext} from "./chat-document-context";
import {useChatMentionsContext} from "./chat-mentions-context";
import {useChatMessagesContext} from "./chat-message-context";
import {useChatPersonaContext} from "./chat-persona-context";
import {useUnsafeAgentCreationContext} from "../agent-creation-context";
import {ChatCommerceTool, ChatGatedTool, ChatImageTool, ChatSocialTool, ChatTool, ChatToolValue, ChatWebTool, useChatToolsContext} from "./chat-tools-context";
import {ChatConversationMode, useChatConversationModeContext} from "./chat-conversation-mode";
import {useChatTask} from "@/hooks/chat/common/useChatTask";
import {ChatTaskType} from "./chat-state-context";

export interface ChatSendQuestionContextValue {
	sendQuestion: (question: string, options?: SendQuestionOptions) => Promise<ChatConversationMessage | undefined>;
	isSendingQuestion: boolean;
	isSendingQuestionRef: MutableRefObject<boolean>;
	isSendingFirstQuestion: boolean;
}

export const ChatSendQuestionContext =
	createContext<ChatSendQuestionContextValue | undefined>(undefined);

export const ChatSendQuestionContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const {surveyIds} = useSearchParams();
	const {mode: conversationMode} = useChatConversationModeContext();
	const {tool} = useChatToolsContext();
	const {setMessages, addMessage, replaceMessage} = useChatMessagesContext();
	const {getImageBase64, clearImage} = useChatImageContext();
	const {documentId, clearDocument} = useChatDocumentContext();
	const {setFollowUps} = useChatFollowUpsContext();
	const {activePersona} = useChatPersonaContext();
	const {workspace: {id: workspaceId} = {}} = useWorkspaceContext();
	const [isSendingFirstQuestion, setIsSendingFirstQuestion] = useState(false);
	const {agent: agentCreationRecord} = useUnsafeAgentCreationContext() || {};
	const {refetch: refetchChatConversation} = useChatConversationContext();

	const {conversation, createConversation} = useChatConversationContext();
	const {mention} = useChatMentionsContext();

	const [chatSendQuestion] = useMutation<ChatSendQuestionResponse>(CHAT_SEND_QUESTION, {
		context: {
			timeout: 5 * 60 * 1000,
		}
	});

	const currentAgent = useMemo(() => {
		if (mention) {
			return mention;
		}

		if (activePersona) {
			return activePersona;
		}

		if (agentCreationRecord) {
			return agentCreationRecord;
		}

		return undefined;
	}, [agentCreationRecord, mention, activePersona]);

	const commands: Record<ChatToolValue | "agent", string | null> = {
        "agent": null,
        [ChatSocialTool.LINKEDIN]: "/linkedin",
        [ChatSocialTool.REDDIT]: "/reddit",
        [ChatSocialTool.TIKTOK]: "/tiktok",
        [ChatSocialTool.YOUTUBE]: "/youtube",
        [ChatSocialTool.X_TWITTER]: "/twitter",
        [ChatSocialTool.INSTAGRAM]: "/instagram",
        [ChatImageTool.ALL]: "/image",
        [ChatImageTool.DALLE]: "/imagesd",
        [ChatImageTool.IMAGEN]: "/imagede",
        [ChatImageTool.STABLE_DIFFUSION]: "/imagen",
        [ChatWebTool.SCRAPE]: "/scrape",
        [ChatWebTool.SCRAPE_ADVANCED]: "/scrape_advanced",
        [ChatWebTool.WEB]: "/web",
        [ChatCommerceTool.AMAZON]: null,
        [ChatGatedTool.WORKSPACE]: null,
    }

	const {run: sendQuestion, loading: isSendingQuestion, loadingRef: isSendingQuestionRef} = useChatTask(ChatTaskType.IS_SENDING_QUESTION, async (
		question: string,
		options?: SendQuestionOptions,
	): Promise<ChatConversationMessage | undefined> => {
		const {conversationInput, sendQuestionInput, createNewConversation} = options || {};
		let chatConversation = conversation;

		const command = tool ? commands[tool] : null;

		question = command ? `${command} ${question}` : question;

		const userMessage = generateFakeInputMessage({
			conversationId: conversation?.id,
			content: question,
		});
		const loadingMessage = generateChatMessageLoading({
			persona: currentAgent,
			conversationId: conversation?.id,
			...sendQuestionInput,
		});

		if (!conversation || createNewConversation) {
			setIsSendingFirstQuestion(true);
			chatConversation = await createConversation(conversationInput);
			setMessages([userMessage, loadingMessage]);
		} else {
			addMessage([userMessage, loadingMessage]);
		}

		if (!chatConversation) {
			throw new Error("Failed to create conversation");
		}

		const variables = {
			question,
			workspaceId,
			conversationId: chatConversation.id,
			image: await getImageBase64() ?? undefined,
			documentId: documentId ?? undefined,
			surveyIds,
			systemAgentId:  options?.systemAgentId,
			messageId: options?.messageId,
			smartPromptEnabled: conversationMode === ChatConversationMode.REASONING,
			...sendQuestionInput,
		};

		clearImage();
		clearDocument();

		const {data: chatSendQuestionData} = await chatSendQuestion({
			variables,
			onError: () => {
				/**
				 * If there is an error it means that the messages state has been disconnected
				 * from the server state. We need to refetch the conversation to get the latest
				 * messages. Events should be able to take it from here.
				 */
				setIsSendingFirstQuestion(false);
				refetchChatConversation();
			},
		});

		if (!chatSendQuestionData) {
			return undefined;
		}

		replaceMessage(loadingMessage.id, chatSendQuestionData.chatSendQuestion);
		setFollowUps(chatSendQuestionData?.chatSendQuestion?.suggestedFollowUps || []);
		setIsSendingFirstQuestion(false);

		return chatSendQuestionData.chatSendQuestion;
	}, [
		currentAgent,
		conversation,
		createConversation,
		getImageBase64,
		surveyIds,
		workspaceId,
		conversationMode,
		tool,
		documentId,
		clearDocument
	]);

	return (
		<ChatSendQuestionContext.Provider value={{
			sendQuestion,
			isSendingQuestion,
			isSendingQuestionRef,
			isSendingFirstQuestion,
		}}>
			{children}
		</ChatSendQuestionContext.Provider>
	);
};

export const useChatSendQuestionContext = (): ChatSendQuestionContextValue => {
	const context = React.useContext(ChatSendQuestionContext);

	if (context === undefined) {
		throw new Error(
			"useChatSendQuestionContext must be used within a ChatSendQuestionContextProvider",
		);
	}

	return context;
};
