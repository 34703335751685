import React, {Dispatch, ReactElement, ReactNode, SetStateAction, createContext, useMemo, useState} from "react";
import {useLocation} from "react-router";
import {useChatSendQuestionContext} from ".";

export enum ChatLayoutMode {
  HOME = "home",
  CHAT = "chat",
}

export interface ChatLayoutModeContextValue {
  mode: ChatLayoutMode;
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChatLayoutModeContext =
  createContext<ChatLayoutModeContextValue | undefined>(undefined);

export const ChatLayoutModeContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const location = useLocation();
  const {isSendingQuestion} = useChatSendQuestionContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const homeMode = useMemo(() => {
		return !location.search.includes("canvasId") && !isSendingQuestion;
	}, [location.search, isSendingQuestion]);

	const mode = homeMode ? ChatLayoutMode.HOME : ChatLayoutMode.CHAT;

  return (
    <ChatLayoutModeContext.Provider value={{mode, isDrawerOpen, setIsDrawerOpen}}>
      {children}
    </ChatLayoutModeContext.Provider>
  );
};

export const useChatLayoutModeContext = (): ChatLayoutModeContextValue => {
  const context = React.useContext(ChatLayoutModeContext);

  if (context === undefined) {
    throw new Error(
      "useChatLayoutModeContext must be used within a ChatLayoutModeContextProvider",
    );
  }

  return context;
};
