import React, {ReactElement, ReactNode, createContext} from "react";

import {useChatConversationContext} from "@/context/chat-contexts";
import {useNavigationContext} from "./navigation-context";
import {useSelectedSourcesContext} from "./selected-sources-context";
import {useTask} from "@/hooks/useTask";
import {useToastContext} from "@/context/toast-context";
import {useSelectedCampaignsContext} from "./selected-campaigns-context";
import {useSearchParamsInvokeCascade} from "@/hooks/useSearchParamsInvokeCascade";

export interface ActionsContextValue {
  submit: () => void;
  isSubmittingRef: React.MutableRefObject<boolean>;
}

export const ActionsContext =
  createContext<ActionsContextValue | undefined>(undefined);

export const ActionsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {conversation} = useChatConversationContext();
  const {updateToast} = useToastContext();
  const {close} = useNavigationContext();
  const {save: saveSelectedSources, isDirty: isSourcesDirty} = useSelectedSourcesContext();
  const {save: saveSelectedCampaigns, isDirty: isCampaignsDirty} = useSelectedCampaignsContext();

  const invokeAllSubmitActions = useSearchParamsInvokeCascade([
    saveSelectedSources,
    saveSelectedCampaigns,
    close,
  ])

  const invokeSourcesSubmitActions = useSearchParamsInvokeCascade([
    saveSelectedSources,
    close,
  ])

  const invokeCampaignsSubmitActions = useSearchParamsInvokeCascade([
    saveSelectedCampaigns,
    close,
  ])

  const {run: submit, loadingRef: isSubmittingRef} = useTask(async () => {
    try {
      /**
       * If there is no conversation, we make no asynchronous calls.
       * Instead we need to update search params 3 times in sequence.
       * In order to do that we use useSearchParamsInvokeCascade.
       */
      if (!conversation && (isSourcesDirty && isCampaignsDirty)) {
        invokeAllSubmitActions();
        return;
      } else if (!conversation && isSourcesDirty) {
        invokeSourcesSubmitActions();
        return;
      } else if (!conversation && isCampaignsDirty) {
        invokeCampaignsSubmitActions();
        return;
      }

      await Promise.all([
        saveSelectedSources(),
        saveSelectedCampaigns()
      ]);

      close();
    } catch (error) {
      updateToast({
        description: "Error while saving training set ids",
        type: "failure",
      });
      console.error("Error while saving training set ids", error);
    }
  });

  return (
    <ActionsContext.Provider value={{submit, isSubmittingRef}}>
      {children}
    </ActionsContext.Provider>
  );
};

export const useActionsContext = (): ActionsContextValue => {
  const context = React.useContext(ActionsContext);

  if (context === undefined) {
    throw new Error(
      "useActionsContext must be used within a ActionsContextProvider",
    );
  }

  return context;
};
