import React, {ReactElement} from "react";

import {Button} from "@/shared/v2";
import {useSelectedSourcesContext} from "../../contexts/selected-sources-context";
import {useSelectedCampaignsContext} from "../../contexts/selected-campaigns-context";

export const ClearAllButton = (): ReactElement => {
  const {set: setSelectedSources} = useSelectedSourcesContext();
  const {set: setSelectedCampaigns} = useSelectedCampaignsContext();

  const handleClearAll = () => {
    setSelectedSources({
      trainingSets: [],
      media: {
        files: [],
        videos: []
      }
    });
    setSelectedCampaigns([]);
  };

  return (
    <Button variant="text" onClick={handleClearAll}>
      Clear All
    </Button>
  );
}
