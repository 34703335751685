import React, {ReactElement, ReactNode, createContext, useCallback, useState} from "react";
import {reject} from "lodash-es";
import {gql, useMutation} from "@apollo/client";

import {useCreateElement} from "../../hooks";
import {useWorkspaceContext} from "../workspace-context";

const UPLOAD = gql`
  mutation Upload($file: Upload!) {
    upload(file: $file) {
      id
      filename
      mimetype
    }
  }
`;

const CREATE_CHAT_DOC_FILE = gql`
  mutation CreateChatDocFile($uploadItemId: GUID!, $workspaceId: GUID!) {
    createChatDocFile(uploadItemId: $uploadItemId, workspaceId: $workspaceId) {
      id
      originalFilename
      originalMimetype
      uploadStatus
    }
  }
`;

export interface ChatDocumentContextValue {
  document: File | null;
  uploadDocument: () => void;
  clearDocument: () => void;
  documentId: string | null;
}

export const ChatDocumentContext =
  createContext<ChatDocumentContextValue | undefined>(undefined);

export const ChatDocumentContextProvider = (
	{children}: {children: ReactNode},
): ReactElement => {
	const [document, setDocument] = useState<File | null>(null);
	const [documentId, setDocumentId] = useState<string | null>(null);
	const {workspace: {id: workspaceId} = {}} = useWorkspaceContext();
	const [uploadMutation] = useMutation(UPLOAD);
	const [createChatDocFile] = useMutation(CREATE_CHAT_DOC_FILE);

	const inputElement = useCreateElement<HTMLInputElement>("input", element => {
		element.setAttribute("type", "file");
		element.setAttribute("accept", ".pdf,.txt,.csv");
		element.onchange = (event) => {
			const target = event.target as HTMLInputElement;

			if (target.files) {
				const file = target.files[0];
				setDocument(file);
				target.value = "";

				uploadMutation({
					variables: {
						file,
					},
				}).then(({data}) => {
					if (data?.upload?.id) {
						createChatDocFile({
							variables: {
								uploadItemId: data.upload.id,
								workspaceId,
							},
						}).then(({data: fileData}) => {
							if (fileData?.createChatDocFile?.id) {
								setDocumentId(fileData.createChatDocFile.id);
							}
						});
					}
				});
			}
		}
	});

	const uploadDocument = (): void => {
		if (inputElement) {
			inputElement.click();
		}
	};

	const clearDocument = (): void => {
		setDocument(null);
		setDocumentId(null);
	};

	return (
		<ChatDocumentContext.Provider value={{
			document,
			uploadDocument,
			clearDocument,
			documentId,
		}}>
			{children}
		</ChatDocumentContext.Provider>
	);
};

export const useChatDocumentContext = (): ChatDocumentContextValue => {
	const context = React.useContext(ChatDocumentContext);

	if (context === undefined) {
		throw new Error(
			"useChatDocumentContext must be used within a ChatDocumentContextProvider",
		);
	}

	return context;
};
