import {ChatTaskType, useChatConversationContext, useChatStateContext} from "@/context/chat-contexts";
import {useEffect, useMemo, useRef} from "react";
import {useSearchParams} from "react-router-dom";

export interface ChatConversationNavigationListenerProps {
  // Invoked when user navigates to different conversation.
  onChange?: () => void;
  // Invoked when user navigates from conversation to home page.
  onClear?: () => void;
  // Invoked when user uses home page to create new conversation.
  onCreate?: () => void;
}

export const useChatConversationNavigationListener = ({
  onChange,
  onClear,
  onCreate,
}: ChatConversationNavigationListenerProps) => {
  const {
    [ChatTaskType.IS_SENDING_QUESTION]: {
      stateRef: isSendingQuestionRef,
    }
  } = useChatStateContext();
  const [searchParams] = useSearchParams();
  const canvasId = useMemo(() => searchParams.get("canvasId") || null, [searchParams]);
  const previousCanvasIdRef = useRef<string | null>(null);

  useEffect(() => {
    const isConversationToConversationChange = canvasId !== previousCanvasIdRef.current && previousCanvasIdRef.current !== null;
    const isConversationToNullChange = canvasId === null && previousCanvasIdRef.current !== null;
    const isNullToConversationChange = canvasId !== previousCanvasIdRef.current && previousCanvasIdRef.current === null;
    const isNullToNullChange = canvasId === null && previousCanvasIdRef.current === null;
    const isSendingQuestion = isSendingQuestionRef.current;

    if (isNullToNullChange) {
      return;
    }

    if (isConversationToConversationChange) {
      onChange?.();
    }

    if (isConversationToNullChange) {
      onClear?.();
    }

    if (isNullToConversationChange && isSendingQuestion) {
      onCreate?.();
    } else if (isNullToConversationChange && !isSendingQuestion) {
      onChange?.();
    }

    previousCanvasIdRef.current = canvasId;
  }, [canvasId]);
}
