import React, {HTMLProps} from "react";
import {Link} from "@/hoc/layout/navigation/link";

import {useChatScrollContext} from "../../../../../context/chat-contexts/chat-scroll-context";
import {useImageHistoryContext} from "../../../../image-studio/contexts";

import styles from "./link-reader.module.scss";

const getServiceInfo = (url: string) => {
	if (url.includes("/sd/")) return {name: "Stable Diffusion", color: "#a69cfe"};
	if (url.includes("/dalle/")) return {name: "Dalle", color: "#D0F8AB"};
	if (url.includes("/google/")) return {name: "Imagen", color: "#A5F0FC"};
	return {name: "User Upload", color: "#a69cfe"};
};

const LinkRenderer = ({href, children}: HTMLProps<HTMLAnchorElement>) => {
	const {scrollListToBottom} = useChatScrollContext();
	const {setImageSrc} = useImageHistoryContext();
	const {name, color} = getServiceInfo(href ?? "");

	const isImage = (content: string) => {
		const imageUrlRegex = /(https:\/\/\S+\.(?:png|jpg|jpeg|gif|webp))/i;
		return imageUrlRegex.test(content);
	};

	const handleClick = (href: string | undefined) => {
		if (href) {
			scrollListToBottom();
			setImageSrc(href);
		}
	};

	if (isImage(href ?? "")) {
		return (
			<div className={styles.imageContainer}>
				<div
					className={styles.imageLabelContainer}
					style={{backgroundColor: color}}
				>
					{name}
				</div>
				<img
					className={styles.amazonImg}
					src={href}
					onClick={() => handleClick(href)}
					alt={children?.[0] as string}
					loading="lazy"
				/>
			</div>
		)
	}

	const linkProps = (() => {
		if (!href) return { to: '/', type: 'redirect' as const, target: '_blank', rel: 'noreferrer' };
		
		try {
			const url = new URL(href);
			// For external URLs, use the full URL
			if (url.protocol === 'http:' || url.protocol === 'https:') {
				return {
					to: href,
					type: 'redirect' as const,
					target: '_blank',
					rel: 'noreferrer'
				};
			}
			// For internal URLs, use NavLink format
			return {
				to: {
					pathname: url.pathname,
					search: url.search
				},
				type: 'redirect' as const,
				target: '_blank',
				rel: 'noreferrer'
			};
		} catch {
			// If URL parsing fails, treat as a pathname
			const [pathname, search] = href.split('?');
			return {
				to: {
					pathname,
					search: search ? `?${search}` : ''
				},
				type: 'redirect' as const,
				target: '_blank',
				rel: 'noreferrer'
			};
		}
	})();

	// Remove any (Valid URL) text from the link text
	const linkText = typeof children === 'string' 
		? children.replace(/\s*\(Valid URL\)\s*/gi, '')
		: children?.[0]?.toString()?.replace(/\s*\(Valid URL\)\s*/gi, '') || '';

	return (
		<Link {...linkProps}>
			{linkText}
		</Link>
	);
};

export default LinkRenderer;
