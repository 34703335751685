import {motion} from "framer-motion";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ActionsDropdown} from "../actions-dropdown";
import {ButtonIcon} from "../../../shared/v2";
import {FileUploadIcon} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./upload-button.module.scss";

const cx = classNames.bind(styles);

export const UploadButton = (): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<motion.div
			className={cx("uploadButton", {isDarkMode})}
		>
			<motion.div
				className={styles.chatUpload}
			>
				<ActionsDropdown
					dataset={false}
					campaign={false}
				>
					<ButtonIcon
						className={styles.icon}
						icon={<FileUploadIcon />}
					/>
				</ActionsDropdown>
			</motion.div>
		</motion.div>
	);
}
