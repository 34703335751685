import React, {ReactElement, useState} from "react";
import { SAMLAuthProvider, signInWithPopup, } from "firebase/auth";
import {auth} from "../../utils/firebase";
import {Button, Input, Modal} from "@/shared/v2";
import {KeyLoginCircleIcon} from "@/icons";

import styles from "./Buttons.module.scss";

export interface SsoSignInButtonProps {
	text?: string;
	id?: string;
}

export const SsoSignInButton = ({
	text = "Sign in with SSO",
	id,
}: SsoSignInButtonProps): ReactElement => {
	const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
	const [email, setEmail] = useState("");

	const handleSignIn = (event): void => {
		event.preventDefault();
		let providerName = '';

		if (email.includes('@example.com')) {
			providerName = 'saml.test-saml';
		}
		else if (email.includes('hillmangroup.com')) {
			providerName = 'saml.azure-hillman';
		} else {
			providerName = 'saml.test-saml';
		}

    const provider = new SAMLAuthProvider(providerName);
		signInWithPopup(auth, provider);
	};

	const closeModal = (): void => {
		setIsEmailModalOpen(false);
		setEmail("");
	};

	return (
		<>
			<button
				id={id}
				onClick={() => setIsEmailModalOpen(true)}
				className={styles.authButton}
			>
				<KeyLoginCircleIcon />
				<span className={styles.authButtonText}>{text}</span>
			</button>

			<Modal
				title="Enter your work email address"
				isOpen={isEmailModalOpen}
				onClose={closeModal}
			>
				<form className={styles.form} onSubmit={handleSignIn}>
					<Input
						type="email"
						placeholder="Email"
						value={email}
						onChange={setEmail}
					/>

					<Button
						type="submit"
						disabled={!email}
					>
						Sign in with SSO
					</Button>
				</form>
			</Modal>
		</>
	);
};
