import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'

import {Body, Tooltip} from "../../../../shared/v2";
import {ChatConversationMessage} from "../../../../models/ai-model";
import LinkRenderer from "./link-reader";
import {MermaidRenderer} from "./mermaid-renderer";

import styles from "./content.module.scss";
import {GroundingEntry} from "../grounding-section/grounding-entry";
import {useThemeMode} from "@/context/theme-mode-context";

const cx = classNames.bind(styles);

const addCitationsToContent = (content, citations) => {
  let modifiedContent = content;

	let sortedCitations = citations.sort((a, b) => a.startPos - b.startPos);

  let offset = 0;
  sortedCitations.forEach((citation) => {
		const citationText = `^${citation.groundingIndex}^`;
    modifiedContent = [
      modifiedContent.slice(0, citation.endPos + offset),
      citationText,
      modifiedContent.slice(citation.endPos + offset)
    ].join('');
    offset += citationText.length;
  });


	return modifiedContent;
};

const removeValidUrlText = (text: string) => {
	return text.replace(/\s*\(Valid URL\)\s*/gi, '');
};

export interface ContentProps {
  message: ChatConversationMessage;
}

interface CodeBlockProps {
  inline?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const CodeBlock: React.FC<CodeBlockProps> = ({inline, className, children}) => {
	const match = /language-(\w+)/.exec(className || "");
	const language = match ? match[1] : "";

	if (!inline && language === "mermaid") {
		return <MermaidRenderer content={String(children).replace(/\n$/, "")} />;
	}

	return (
		<code className={className}>
			{children}
		</code>
	);
};

export const Content = ({message}: ContentProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const handleChart = (content: string) => {
		const imageUrlRegex = /(https:\/\/\S+\.(?:png|jpg|jpeg|gif))/i;
		const match = content.match(imageUrlRegex);

		if (match) {
			const imageUrl = match[0];
			const remainingText = content.replace(imageUrlRegex, "").trim();
			const resultArray = [imageUrl, remainingText];
			return resultArray;
		} else {
			return ["", content];
		}
	};

	const citations = useMemo(() => {
		let citations = [] as any;

		message.groundingData?.forEach((grounding, index) => {
			grounding.citations.forEach((citation) => {
				citations.push({
					...citation,
					groundingIndex: index + 1 ,
					grounding: grounding,
				});
			});
		});

		return citations;
	}, [message?.groundingData])

	const cleanContent = removeValidUrlText(message.content);
	const contentWithCitations = addCitationsToContent(cleanContent, citations);

	return (
		<Body
			className={cx(message.type === "image" ? styles.imageContent : styles.content)}
			color="text-secondary"
		>
			{message.type === "chart" ? (
				<>
					<img style={{width: "100%"}} src={handleChart(cleanContent)[0]} />
					<ReactMarkdown
						remarkPlugins={[remarkGfm]}
						components={{
							a: LinkRenderer,
							p: ({children}) => <span className={styles.paragraph}>{children}</span>,
						}}
					>
						{handleChart(cleanContent)[1]}
					</ReactMarkdown>
				</>
			) : (
				<ReactMarkdown
					remarkPlugins={[remarkGfm, supersub]}
					components={{
						a: LinkRenderer,
						p: ({children}) => <span className={styles.paragraph}>{children}</span>,
						code: CodeBlock as any,
						table: ({children}) => <table className={cx("markdownTable", {isDarkMode})}>{children}</table>,
						thead: ({children}) => <thead>{children}</thead>,
						tbody: ({children}) => <tbody>{children}</tbody>,
						tr: ({children}) => <tr>{children}</tr>,
						th: ({children}) => <th>{children}</th>,
						td: ({children}) => <td>{children}</td>,
						img: ({src, alt}) => (
							<img
								src={src}
								alt={alt}
								className={styles.markdownImage}
								onClick={() => window.open(src, '_blank')}
							/>
						),
						sup: ({ children }) => {
							const citation = typeof(children) === 'string' && citations.find(cit => cit.groundingIndex === parseInt(children))

							if (!citation?.grounding) {
								return <sup className={cx("citation", {isDarkMode})}>{children}</sup>
							}
							return <Tooltip
								placement="top"
								interactive
								containerClassname={styles.tooltipWrapper}
								className={styles.tooltip}
								content={
								<GroundingEntry position={citation.groundingIndex} entry={citation.grounding} tooltipInside={false} citationMode={true} />
							}>
								<sup className={cx("citation", {isDarkMode})}>{children}&nbsp;</sup>
							</Tooltip>
						}
					}}
				>
					{contentWithCitations}
				</ReactMarkdown>
			)}
		</Body>
	);
}
