import React, {ReactElement, useState} from "react";
import {formatDate, NewAvatar, convertToClockTime} from "../../../shared";
import {UPDATE_REEL} from "../../../graphql/mutations/reel-mutations";
import {Reel} from "../../../models/reels";
import {useMutation} from "@apollo/client";
import styles from "./clip-metadata.module.scss";
import {Button, TextArea} from "../../../shared/v2";

export interface ClipMetadataProps {
	/**
	 * The reel (!)
	 */
	reel: Reel;
	togglePreview: () => void;
}

/**
 * This component displays information about the reel. Mainly the
 * name, who created it, and, eventually, how many plays it has.
 * Will also allow a user to edit this info based on editing state.
 *
 * @param reel The reel we are looking at
 * @param publishStatus The current status of the Reel's video
 * @param updateReelCallback Callback to update the reel name or description
 * @param hasClips Determines whether to show play button or not.
 */
const ClipMetadata = (props: ClipMetadataProps): ReactElement => {
	const {reel, togglePreview} = props;
	const [updateReel] = useMutation(UPDATE_REEL);
	const [description, setDescription] = useState(reel.description || "");

	const handleDescription = (newValue: string): void => {
		updateReel({
			variables: {
				id: reel.id,
				changes: {description: newValue},
				deletions: {description: !newValue},
			},
		});
	};

	return (
		<div className={styles.container}>
			<div className={styles.info}>
				<NewAvatar user={reel.creator} />
				<div className={styles.authorInfo}>
					<span>
						by {reel.creator.firstName} {reel.creator?.lastName || reel.creator?.lastInitial}
					</span>
					<span className={styles.date}>{reel.createdAt && formatDate(reel.createdAt)}</span>
				</div>
				<div className={styles.duration}>
					Total reel duration: <span>{convertToClockTime(reel.duration || 0)}</span>
				</div>
				{reel.duration > 0 && <Button variant="outlined" onClick={togglePreview}>Preview</Button>}
			</div>
			<h2>Description</h2>
			<TextArea
				value={description}
				onChange={setDescription}
				onBlur={handleDescription}
				placeholder="Enter description"
				maxLength={255}
				className={styles.description}
			/>
		</div>
	);
};

export {ClipMetadata};
