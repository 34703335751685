import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {DocumentsFolderFileIcon, HornMegaphoneCampaignIcon, PhotoImageIcon, DocumentIcon} from "../../../icons";
import {Dropdown} from "../../../shared/v2";
import {SelectCampaignModal} from "../../select-campaign-modal";
import {TrainingSet} from "../../../models";
import {useChatImageContext} from "../../../context/chat-contexts/chat-image-context";
import {useChatDocumentContext} from "../../../context/chat-contexts/chat-document-context";
import {useChatCampaignContext, useChatSourcesContext} from "../../../context/chat-contexts";
import {useFileUploader} from "../../useFileUploader";
import {useModalActions} from "../../../hooks";
import {SelectChatSourcesModal} from "@/canvas/select-chat-sources-modal";

import styles from "./actions-dropdown.module.scss";
import {DropdownProps} from "@/shared/v2/dropdown";

const bStyles = classNames.bind(styles);

export interface ActionsDropdownProps {
	children: Pick<DropdownProps, "trigger">["trigger"];
  disabled?: boolean;
  className?: string;
  campaign?: boolean;
  dataset?: boolean;
  image?: boolean;
  document?: boolean;
  onClose?: () => void;
  agent?: boolean;
}

export const ActionsDropdown = ({
  children,
  className,
  disabled = false,
  campaign = true,
  dataset = true,
  image = true,
  document = true,
}: ActionsDropdownProps): ReactElement => {
  const {
    active: {trainingSets: activeTrainingSets},
    isUpdatingDisabled: isSourcesUpdatingDisabled,
    save: saveSources,
  } = useChatSourcesContext();

  const {isUpdatingDisabled: isCampaignUpdatingDisabled} = useChatCampaignContext();
  const {uploadImage} = useChatImageContext();
  const {uploadDocument} = useChatDocumentContext();

  const {
    value: isSelectCampaignModalOpen,
    open: openSelectCampaignModal,
    close: closeSelectCampaignModal,
  } = useModalActions();

  const {
    value: isSelectChatSourcesModalOpen,
    open: openSelectChatSourcesModal,
    close: closeSelectChatSourcesModal,
  } = useModalActions({search: "chat-sources"});

  const {modals: fileUploadModals /*, openUploadModal*/} = useFileUploader({
    setTrainingSet: (trainingSet: TrainingSet) => {
      saveSources({
        trainingSets: [...(activeTrainingSets?.map((trainingSet) => trainingSet.id) || []), trainingSet.id],
      });
    },
  });

  return (
    <>
      <Dropdown
        className={bStyles("actionsDropdown", className)}
        position="bottom-start"
        disabled={disabled}
        space={12}
        trigger={children}
        ariaLabel="plus-button"
        items={[
          campaign
            ? {
                label: "Include Campaign",
                disabled: isCampaignUpdatingDisabled,
                icon: <HornMegaphoneCampaignIcon />,
                onClick: openSelectCampaignModal,
              }
            : undefined,
          dataset
            ? {
                label: "Include Sources",
                disabled: isSourcesUpdatingDisabled,
                icon: <DocumentsFolderFileIcon />,
                onClick: openSelectChatSourcesModal,
              }
            : undefined,
          image
            ? {
                label: "Upload Image",
                icon: <PhotoImageIcon />,
                onClick: uploadImage,
              }
            : undefined,
          document
            ? {
                label: "Upload Document",
                icon: <DocumentIcon />,
                onClick: uploadDocument,
              }
            : undefined,
        ]}
      />

      <SelectCampaignModal isOpen={isSelectCampaignModalOpen} onClose={closeSelectCampaignModal} />

      {dataset && (
        <SelectChatSourcesModal
          isOpen={isSelectChatSourcesModalOpen}
          onClose={closeSelectChatSourcesModal}
        />
      )}

      {fileUploadModals}
    </>
  );
};
