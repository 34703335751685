import React, {Dispatch, ReactElement, ReactNode, SetStateAction, createContext} from "react";
import {useChatConversationNavigationListener} from "@/hooks/chat";

export enum ChatConversationMode {
  REASONING = "reasoning",
  CONVERSATION = "conversation",
}

export interface ChatConversationModeContextValue {
  mode: ChatConversationMode;
  setMode: Dispatch<SetStateAction<ChatConversationMode>>;
}

export const ChatConversationModeContext =
  createContext<ChatConversationModeContextValue | undefined>(undefined);

export const ChatConversationModeContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const [mode, setMode] = React.useState<ChatConversationMode>(ChatConversationMode.REASONING);

  useChatConversationNavigationListener({
    onChange: () => {
      setMode(ChatConversationMode.REASONING);
    },
    onClear: () => {
      setMode(ChatConversationMode.REASONING);
    }
  });

  return (
    <ChatConversationModeContext.Provider value={{mode, setMode}}>
      {children}
    </ChatConversationModeContext.Provider>
  );
};

export const useChatConversationModeContext = (): ChatConversationModeContextValue => {
  const context = React.useContext(ChatConversationModeContext);

  if (context === undefined) {
    throw new Error(
      "useChatConversationModeContext must be used within a ChatConversationModeContextProvider",
    );
  }

  return context;
};
