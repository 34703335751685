import {useEffect, useMemo} from "react";

import {isChatFakeInputMessage} from "../../../canvas/chat/utils/isChatFakeInputMessage";
import {isChatMessageLoading} from "../../../canvas/chat/utils/isChatMessageLoading";
import {
	useChatEventsContext,
	useChatMessagesContext,
	useChatPersonaContext
} from "../../../context/chat-contexts";
import {ChatConversationMessage, ChatConversationMessageRole} from "../../../models/ai-model";
import {ConversationEventTypes, ConversationResponseCreated, ConversationUserMessageCreated} from "../../../models/subscriptions";


type MessagePair = [
  ConversationUserMessageCreated,
  ConversationResponseCreated
];

/* This hook is used for listening to event-based chat list updates. */
export const useChatListEventUpdates = () => {
	const {personas} = useChatPersonaContext();
	const {messages, replaceMessage, addMessage} = useChatMessagesContext();

	const {events: {
		[ConversationEventTypes.USER_MESSAGE_CREATED]: askedQuestions,
		[ConversationEventTypes.RESPONSE_CREATED]: createdResponses,
	}, removeEvent} = useChatEventsContext();

	const hasFakeMessages = useMemo(() => {
		return messages.some(m =>  isChatFakeInputMessage(m) || isChatMessageLoading(m));
	}, [messages]);

	/*
    Handles case when question has been asked in current session
    and we already have response message from backend.
  */
	useEffect(() => {
		const hasNoFakeInputMessages = !messages.some(m => isChatFakeInputMessage(m));

		if (hasNoFakeInputMessages || askedQuestions.length === 0 || createdResponses.length === 0) {
			return;
		}

		const replacements: [string, ChatConversationMessage][] = createdResponses.reduce((acc, responseEvent) => {
			// Find whether the response from the even is already in the list
			const responseIndex = messages.findIndex(m => m.id === responseEvent.data.conversationResponseId);

			if (responseIndex === -1) {
				return acc;
			}

			// Check if we have event with question that corresponds to the response
			const questionEvent = askedQuestions.find(q => q.data.questionId === responseEvent.data.questionId);

			if (!questionEvent) {
				return acc;
			}

			// We can update message list and remove events
			removeEvent(responseEvent);
			removeEvent(questionEvent);
			const fakeMessage = messages[responseIndex - 1];

			if (!isChatFakeInputMessage(fakeMessage)) {
				return acc;
			}

			return [...acc, [fakeMessage.id, {
				conversationId: questionEvent.data.conversationId,
				id: questionEvent.data.questionId,
				content: questionEvent.data.content,
				role: ChatConversationMessageRole.USER,
				position: questionEvent.data.position,
			} as ChatConversationMessage]];
		}, [] as [string, ChatConversationMessage][]);

		for (const [fakeMessageId, question] of replacements) {
			replaceMessage(fakeMessageId, question);
		}
	}, [askedQuestions, createdResponses, messages]);

	/*
    Handles case when both question and response come
    from events and we need to add them to the list.
  */
	useEffect(() => {
		if (hasFakeMessages || askedQuestions.length === 0 || createdResponses.length === 0) {
			return;
		}

		const pairs: MessagePair[] = askedQuestions.reduce((acc, questionEvent) => {
			const question = questionEvent.data;

			if (messages.some(m => m.id === question.questionId)) {
				removeEvent(questionEvent);
				return acc;
			}

			const responseEvent = createdResponses.find(r => r.data.questionId === question.questionId);
			const response = responseEvent?.data;

			if (!response) {
				return acc;
			}

			if (messages.some(m => m.id === response?.conversationResponseId)) {
				removeEvent(responseEvent)
				return acc;
			}

			if (response) {
				return [...acc, [questionEvent, responseEvent] as MessagePair];
			}

			return acc;
		}, [] as MessagePair[]);

		for (const [questionEvent, responseEvent] of pairs) {
			const question = questionEvent.data;
			const response = responseEvent.data;
			removeEvent(questionEvent);
			removeEvent(responseEvent);
			addMessage([{
				conversationId: question.conversationId,
				id: question.questionId,
				content: question.content,
				role: ChatConversationMessageRole.USER,
			} as ChatConversationMessage, {
				content: response.content,
				conversationId: response.conversationId,
				id: response.conversationResponseId,
				persona: personas.find(p => p.id === response.agentId),
				role: ChatConversationMessageRole.ASSISTANT,
				type: response.type,
			} as ChatConversationMessage]);
		}
	}, [askedQuestions, createdResponses, hasFakeMessages]);

	/**
	 * Handles case when we have correct reqeuset message and response message
	 * with empty content (eg. user asked question, but then reloaded page before receiving response).
	 */
	useEffect(() => {
		const emptyResponses = messages.filter(m => m.role !== ChatConversationMessageRole.USER && m.content === "");

		if (emptyResponses.length === 0 || createdResponses.length === 0) {
			return;
		}

		const replacements = emptyResponses.reduce((acc, emptyResponse) => {
			const responseEvent = createdResponses.find(r => r.data.conversationResponseId === emptyResponse.id);

			if (!responseEvent) {
				return acc;
			}

			removeEvent(responseEvent);
			return [...acc, {
				...emptyResponse,
				content: responseEvent.data.content,
			} as ChatConversationMessage];
		}, [] as ChatConversationMessage[]);

		if (replacements.length === 0) {
			return;
		}

		for (const replacement of replacements) {
			replaceMessage(replacement.id, replacement);
		}
	}, [askedQuestions, createdResponses, messages]);
};
