import {Event} from "./typings.js";

export enum ConversationEventTypes {
	STARTED = "ConversationStarted",
	NAME_CHANGED = "ConversationNameChanged",
	SOURCES_CHANGED = "ConversationSourcesChanged",
	PERSONA_CHANGED = "ConversationPersonaChanged",
	RESPONSE_ERROR_OCCURED = "ConversationResponseErrorOccured",
	RESPONSE_STATE_CHANGED = "ConversationResponseStateChanged",
	USER_MESSAGE_CREATED = "ConversationUserMessageCreated",
	RESPONSE_CREATED = "ConversationResponseCreated",
}

export type ConversationStarted = Event<
	ConversationEventTypes.STARTED,
	{
		conversationId: string;
		workspaceId: string;
	}
>;

export type ConversationNameChanged = Event<
	ConversationEventTypes.NAME_CHANGED,
	{
		conversationId: string;
		name: string;
		workspaceId: string;
	}
>;

export type ConversationSourcesChanged = Event<
	ConversationEventTypes.SOURCES_CHANGED,
	{
		conversationId: string;
		datasetsIds: string[];
		campaignsIds: string[];
	}
>;

export type ConversationPersonaChanged = Event<
	ConversationEventTypes.PERSONA_CHANGED,
	{
		conversationId: string;
		personaId: string | null;
	}
>;

export type ConversationResponseErrorOccured = Event<
	ConversationEventTypes.RESPONSE_ERROR_OCCURED,
	{
		conversationId: string;
		conversationResponseId: string;
		error: string;
	}
>;

export type ConversationResponseStateChanged = Event<
	ConversationEventTypes.RESPONSE_STATE_CHANGED,
	{
		conversationId: string;
		state: string;
		conversationResponseId: string;
		content: string;
	}
>;

export type ConversationUserMessageCreated = Event<
	ConversationEventTypes.USER_MESSAGE_CREATED,
	{
		questionId: string;
		conversationId: string;
		content: string;
		position: number;
	}
>;

export type ConversationResponseCreated = Event<
	ConversationEventTypes.RESPONSE_CREATED,
	{
		conversationId: string;
		conversationResponseId: string;
		questionId: string;
		agentId: string | null;
		chunkPosition: number;
		content: string;
		type: string;
		hasGrounding: boolean;
		lastChunk: boolean;
	}
>;
