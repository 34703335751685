import React, {Dispatch, ReactElement, ReactNode, SetStateAction, createContext, useMemo, useState} from "react";
import {ChatConversationMode, useChatConversationModeContext} from "./chat-conversation-mode";
import {useChatConversationNavigationListener} from "@/hooks/chat";

export enum ChatTool {
  COMMERCE = "COMMERCE",
  SOCIAL = "SOCIAL",
  IMAGE = "IMAGE",
  WEB = "WEB",
  GATED = "GATED",
  AGENT="AGENT",
}

export enum ChatSocialTool {
  TIKTOK = "SOCIAL_TIKTOK",
  REDDIT = "SOCIAL_REDDIT",
  LINKEDIN = "SOCIAL_LINKEDIN",
  YOUTUBE = "SOCIAL_YOUTUBE",
  X_TWITTER = "SOCIAL_X_TWITTER",
  INSTAGRAM = "SOCIAL_INSTAGRAM",
}

export enum ChatCommerceTool {
  AMAZON = "COMMERCE_AMAZON",
}

export enum ChatImageTool {
  STABLE_DIFFUSION = "IMAGE_STABLE_DIFFUSION",
  DALLE = "IMAGE_DALLE",
  IMAGEN = "IMAGE_IMAGEN",
  ALL = "IMAGE_ALL",
}

export enum ChatWebTool {
  SCRAPE = "WEB_SCRAPE",
  SCRAPE_ADVANCED = "WEB_SCRAPE_ADVANCED",
  WEB = "WEB_WEB",
}

export enum ChatGatedTool {
  WORKSPACE = "GATED_WORKSPACE",
}

export type ChatToolValue = ChatSocialTool | ChatCommerceTool | ChatImageTool | ChatWebTool | ChatGatedTool;

export interface ChatToolsContextValue {
  tool: ChatToolValue | null;
  commerceTool: ChatCommerceTool;
  socialTool: ChatSocialTool;
  imageTool: ChatImageTool;
  webTool: ChatWebTool;
  gatedTool: ChatGatedTool;
  setTool: Dispatch<SetStateAction<ChatTool | null>>;
  setCommerceTool: Dispatch<SetStateAction<ChatCommerceTool>>;
  setSocialTool: Dispatch<SetStateAction<ChatSocialTool>>;
  setImageTool: Dispatch<SetStateAction<ChatImageTool>>;
  setWebTool: Dispatch<SetStateAction<ChatWebTool>>;
  setGatedTool: Dispatch<SetStateAction<ChatGatedTool>>;
}

export const ChatToolsContext =
  createContext<ChatToolsContextValue | undefined>(undefined);

export const ALLOWED_TOOLS_PER_CONVERSATION_MODE_MAP: Record<ChatConversationMode, ChatToolValue[]> = {
  [ChatConversationMode.CONVERSATION]: [
    Object.values(ChatSocialTool),
    Object.values(ChatImageTool),
    Object.values(ChatCommerceTool),
    Object.values(ChatWebTool),
    Object.values(ChatGatedTool),
  ].flat() as ChatToolValue[],
  [ChatConversationMode.REASONING]: [],
}

export const ChatToolsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {mode: conversationMode} = useChatConversationModeContext();
  const [selectedTool, setSelectedTool] = useState<ChatTool | null>(null);
  const [currentSocialTool, setCurrentSocialTool] = useState<ChatSocialTool>(ChatSocialTool.TIKTOK);
  const [currentCommerceTool, setCurrentCommerceTool] = useState<ChatCommerceTool>(ChatCommerceTool.AMAZON);
  const [currentImageTool, setCurrentImageTool] = useState<ChatImageTool>(ChatImageTool.DALLE);
  const [currentWebTool, setCurrentWebTool] = useState<ChatWebTool>(ChatWebTool.WEB);
  const [currentGatedTool, setCurrentGatedTool] = useState<ChatGatedTool>(ChatGatedTool.WORKSPACE);

  const resetTools = () => {
    setSelectedTool(null);
    setCurrentSocialTool(ChatSocialTool.TIKTOK);
    setCurrentCommerceTool(ChatCommerceTool.AMAZON);
    setCurrentImageTool(ChatImageTool.DALLE);
    setCurrentWebTool(ChatWebTool.WEB);
    setCurrentGatedTool(ChatGatedTool.WORKSPACE);
  }

  useChatConversationNavigationListener({
    onChange: resetTools,
    onClear: resetTools
  });

  const activeTool = useMemo(() => {
    if (!selectedTool) {
      return null;
    }

    const getTool = (): ChatToolValue => {
      switch (selectedTool) {
        case ChatTool.SOCIAL:
          return currentSocialTool;
        case ChatTool.COMMERCE:
          return currentCommerceTool;
        case ChatTool.IMAGE:
          return currentImageTool;
        case ChatTool.WEB:
          return currentWebTool;
        case ChatTool.GATED:
          return currentGatedTool;
        case ChatTool.AGENT:
          return ChatGatedTool.WORKSPACE; // Default to workspace for agent tool
        default:
          return ChatGatedTool.WORKSPACE; // Provide a safe default value that matches ChatToolValue type
      }
    }

    const result = getTool();

    if (ALLOWED_TOOLS_PER_CONVERSATION_MODE_MAP[conversationMode].includes(result)) {
      return result;
    }

    return null;
   }, [selectedTool, currentSocialTool, currentCommerceTool, currentGatedTool, currentImageTool, currentWebTool, conversationMode]);

  return (
    <ChatToolsContext.Provider value={{
      tool: activeTool,
      socialTool: currentSocialTool,
      imageTool: currentImageTool,
      webTool: currentWebTool,
      commerceTool: currentCommerceTool,
      gatedTool: currentGatedTool,
      setTool: setSelectedTool,
      setSocialTool: setCurrentSocialTool,
      setImageTool: setCurrentImageTool,
      setWebTool: setCurrentWebTool,
      setCommerceTool: setCurrentCommerceTool,
      setGatedTool: setCurrentGatedTool,
    }}>
      {children}
    </ChatToolsContext.Provider>
  );
};

export const useChatToolsContext = (): ChatToolsContextValue => {
  const context = React.useContext(ChatToolsContext);

  if (context === undefined) {
    throw new Error(
      "useChatToolsContext must be used within a ChatToolsContextProvider",
    );
  }

  return context;
};
