import React, {ReactElement, useMemo} from "react";
import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";

import {Body, ButtonIcon, Tooltip} from "../../shared/v2";
import {BinDeleteIcon, CopyDocumentsIcon} from "../../icons";
import {useToastContext} from "../../context/toast-context";
import {useChatMessagesContext} from "../../context/chat-contexts";
import {ChatConversationMessage} from "../../models/ai-model";
import {CHAT_DELETE_MESSAGE_PAIR} from "../../graphql/mutations/ai-mutations";
import {ConfirmActionModal} from "@/shared/v2/modals/confirm-action-modal";
import {GET_CHAT_CONVERSATION} from "@/graphql/queries/ai-models-queries";

import styles from "./chat-actions.module.scss";

const bStyles = classNames.bind(styles);

interface ButtonTooltipProps {
	icon: ReactElement;
	text: string;
	onClick?: (event) => void;
	iconClassName?: string;
	disabled?: boolean;
}

const ButtonTooltip = ({icon, text, onClick, iconClassName, disabled}: ButtonTooltipProps) => {
	return (
		<Tooltip
			content={
				<Body size="xs" color="text-tertiary">
					{text}
				</Body>
			}
			placement={"top"}
		>
			<ButtonIcon
				size="small"
				color="gray-modern"
				className={iconClassName}
				icon={icon}
				onClick={onClick}
				disabled={disabled}
			/>
		</Tooltip>
	);
};

export interface UserMessageActionsProps {
	className?: string;
	message: ChatConversationMessage;
}

export const UserMessageActions = ({
	className,
	message,
}: UserMessageActionsProps): ReactElement => {
	const {updateToast} = useToastContext();
	const {messages} = useChatMessagesContext()
	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
	const [deleteMessagePair, {loading}] = useMutation(CHAT_DELETE_MESSAGE_PAIR, {
		onCompleted: () => {
			updateToast({
				description: "Message deleted",
				type: "success",
			});
		},
		refetchQueries: [GET_CHAT_CONVERSATION]
	});

	const handleDeleteClick = (event) => {
		event.stopPropagation();
		setShowDeleteConfirm(true);
	};

	const handleConfirmDelete = async () => {
		try {
			await deleteMessagePair({
				variables: {
					messageId: message.id,
				},
			});
			setShowDeleteConfirm(false);
		} catch (error) {
			updateToast({
				description: error instanceof Error ? error.message : "Failed to delete message",
				type: "failure",
			});
		}
	};

	const handleCancelDelete = () => {
		setShowDeleteConfirm(false);
	};

	const handleCopy = async (event) => {
		event.stopPropagation();
		try {
			await navigator.clipboard.writeText(message.content);
			updateToast({
				description: "Message copied to clipboard",
				type: "success",
			});
		} catch (error) {
			updateToast({
				description: "Failed to copy message",
				type: "failure",
			});
		}
	};

	const deleteDisabled = useMemo(() => {
		if (loading) {
			return true;
		}

		if (message.id.includes("FAKE")) {
			return true;
		}

		const responseMessage = messages?.find((m) => m.position === message.position + 1);
		const isResponseMessageLoading = !responseMessage || responseMessage?.id?.includes("LOADING");

		if (isResponseMessageLoading) {
			return true;
		}

		return false;

	}, [message, messages, loading]);

	return (
		<>
			<div className={bStyles("actions", className)}>
				<ButtonTooltip
					icon={<CopyDocumentsIcon />}
					text="Copy message"
					onClick={handleCopy}
				/>
				<ButtonTooltip
					icon={<BinDeleteIcon />}
					text="Delete message"
					onClick={handleDeleteClick}
					disabled={deleteDisabled}
				/>
			</div>


			<ConfirmActionModal
				portal
				title="Delete Message"
				description="This will delete both your message and the assistant's response. Are you sure you want to continue?"
				isOpen={showDeleteConfirm}
				onClose={handleCancelDelete}
				onConfirm={handleConfirmDelete}
			/>
		</>
	);
};
